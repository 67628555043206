import React, { useState } from "react";

import Box from "@material-ui/core/Box";

import FacebookIconHover from '../assets/icons/facebookHover.svg';
import FacebookIcon from '../assets/icons/facebook.svg';
import InstagramIcon from '../assets/icons/instagram.svg';
import InstagramIconHover from '../assets/icons/instagramHover.svg';
import YoutubeIconHover from '../assets/icons/yotubeHover.svg';
import YoutubeIcon from '../assets/icons/youtube.svg';

export default function SocialIcons() {
    const [youtubeIcon, setYoutubeIcon] = useState(YoutubeIcon);
    const [instagramIcon, setInstagramIcon] = useState(InstagramIcon);
    const [facebookIcon, setFacebookIcon] = useState(FacebookIcon);
  
    const MouseEnterEventYotube = () => {
      setYoutubeIcon(YoutubeIconHover)
    }
    const MouseEnterEventInstagram = () => {
      setInstagramIcon(InstagramIconHover)
    }
    const MouseEnterEventFacebook = () => {
      setFacebookIcon(FacebookIconHover)
    }
  
    const MouseOutEventYotube = () => {
      setYoutubeIcon(YoutubeIcon)
    }
    const MouseOutEventInstagram = () => {
      setInstagramIcon(InstagramIcon)
    }
    const MouseOutEventFacebook = () => {
      setFacebookIcon(FacebookIcon)
    }

    return (
        <Box className='wrapper-social-icons'>
            <a href="https://www.facebook.com/slovenskedivadlotanca" rel="noreferrer" target="_blank">
                <img className="socialIcon" src={facebookIcon} alt="Facebook icon"
                  onMouseEnter={MouseEnterEventFacebook}
                  onMouseOut={MouseOutEventFacebook} 
                  onBlur={MouseOutEventFacebook}
                />
            </a>
            <a href="https://www.instagram.com/slovenskedivadlotanca" rel="noreferrer" target="_blank">
                <img className="socialIcon" src={instagramIcon} alt="Instagram icon"
                  onMouseEnter={MouseEnterEventInstagram}
                  onMouseOut={MouseOutEventInstagram}
                  onBlur={MouseOutEventInstagram}
                />
            </a>
            <a href="https://www.youtube.com/channel/UCUQhfRSnao_ySkYLX2fqL7A" rel="noreferrer" target="_blank">
                <img className="socialIcon" src={youtubeIcon} alt="Youtube icon"
                  onMouseEnter={MouseEnterEventYotube}
                  onMouseOut={MouseOutEventYotube}
                  onBlur={MouseOutEventYotube}
                />
            </a>
        </Box>
    );
}